import React from 'react'
import me from "../images/Aditya_Boddepalli.jpg"
import aws_ccp from "../images/aws_ccp.png"
import aws_saa from "../images/aws_saa.png"
import Socials from './Socials'

function Home() {
    return (
        <div name="screen" className='w-full bg-gradient-to-b overflow-auto from-zinc-400 to-slate-300'>
            <div name="imgAndIntro" className='max-w-screen-lg sm:h-full md:h-screen mx-auto flex flex-col items-center justify-center px-4 md:flex-row'>
                <div name="Img" >
                    <img src={me} alt='my pretty face' className='rounded-2xl mx-auto 
                   my-32 w-2/3 md:my-0 md:h-5/6 md:w-11/12 lg:float-left'></img>

                </div>
                <div name="intro" className='flex flex-col justify-center h-full px-10 mt-36 lg:float-right'>
                    <h2 className='text-4xl md:text-7xl font-bold text-black'>
                        Welcome!
                    </h2>
                    <h2 className='text-4xl md:text-6xl font-bold text-violet-500'>
                        I am Aditya Boddepalli
                    </h2>
                    <p className='text-2xl md:text-3xl text-black py-4 max-w-md'>
                        I'm a fourth-year undergraduate student at Northeastern University majoring in Computer Science, graduating in May 2025. My interests lie in <span className='text-bold text-sky-600'>Cloud Computing, Web Dev, and Artificial Intelligence.</span>
                    </p>

                    <div className='flex flex-row'>
                        <a rel="noreferrer" target='_blank' href='https://www.credly.com/badges/cfc1b3ca-1f68-4a54-8b1e-e5224124f4bb/public_url'>
                            <img src={aws_ccp} alt='aws ccp badge' className='mx-auto rounded-2xl object-contain hover:scale-110 duration-150' />
                        </a>
                        <a rel="noreferrer" target='_blank' href='https://www.credly.com/badges/cacb76da-4ccb-4d68-ac37-216399232e72/public_url'>
                            <img src={aws_saa} alt='aws ccp badge' className='mx-auto rounded-2xl object-contain hover:scale-110 duration-150' />
                        </a>
                    </div>
                </div>


                <Socials />

            </div>


        </div>
    )
}

export default Home
